<script setup lang="ts">
import { PropType } from 'vue'
import { Category, Tag } from '@/models/content'

const props = defineProps({
  slug: {
    type: [String, Number],
    required: true,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  excerpt: {
    type: String,
    required: true,
  },
  pills: {
    type: Array as PropType<Tag[] | Category[]>,
    default: () => [],
  },
  hideDate: {
    type: Boolean,
    default: false,
  },
  disablePadding: {
    type: Boolean,
    default: false,
  },
})

const href = useContentHref('article', props.slug)
</script>

<template>
  <BaseLink :href="href" as="div" variant="unstyled">
    <article class="group cursor-pointer" :class="{ 'py-4': !disablePadding }">
      <p v-if="!hideDate" class="text-xs font-semibold text-s-500">
        {{ publishedDate }}
      </p>

      <BaseLink :href="href" as="a" variant="unstyled">
        <h3
          class="text-sm font-semibold group-hover:underline"
          :class="{ 'mt-2': !disablePadding }"
        >
          {{ title }}
        </h3>
      </BaseLink>

      <div class="custom-line-clamp mt-2 text-sm" v-html="excerpt"></div>

      <div v-if="pills.length" class="mt-4 flex flex-wrap gap-2">
        <LinkedPill
          v-for="pill in pills"
          :key="pill.id"
          :slug="pill.slug"
          variant="black"
        >
          {{ pill.name }}
        </LinkedPill>
      </div>
    </article>
  </BaseLink>
</template>
