<script setup lang="ts">
import { PropType } from 'vue'
import { ContentType } from '@/models/content'

const props = defineProps({
  state: {
    type: String,
    default: 'inactive',
    validator(value: string) {
      return ['active', 'paused', 'inactive'].includes(value)
    },
  },
  contentType: {
    type: String as PropType<ContentType>,
    default: 'article',
    validator(value: string) {
      return ['article', 'chart', 'video'].includes(value)
    },
  },
  slug: {
    type: [String, Number],
    required: true,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  coverUrl: {
    type: String,
    required: true,
  },
})

const href = useContentHref(props.contentType, props.slug)

const activeOrPaused = computed(() =>
  ['active', 'paused'].includes(props.state),
)

const isTabletOrDesktop = useTabletOrDesktop()

const emit = defineEmits(['time-up', 'pause', 'resume', 'select'])

const pause = () => activeOrPaused.value && emit('pause')
const resume = () => activeOrPaused.value && emit('resume')
const select = () => isTabletOrDesktop.value && emit('select')

const handleMouseover = () => {
  select()
  pause()
}
</script>

<template>
  <article
    data-testid="headline-card"
    class="flex flex-col"
    @mouseover="handleMouseover"
    @mouseleave="resume"
  >
    <!-- Loading bar -->
    <div class="hidden h-line-6 overflow-hidden bg-s-400 md:block">
      <div
        v-if="activeOrPaused"
        class="progress-animation h-full origin-left bg-p-500"
        :class="{ 'pause-animation': state === 'paused' }"
        @animationend="emit('time-up')"
      />
    </div>

    <!-- Headline -->
    <section class="grow">
      <!-- Desktop -->
      <div class="mt-4 hidden h-full md:block">
        <p class="text-xs font-semibold text-s-500">
          {{ publishedDate }} · {{ duration }} min read
        </p>
        <BaseLink class="block h-full" variant="unstyled" :href="href">
          <h2
            data-testid="headline"
            class="mt-1 cursor-pointer text-lg font-semibold leading-snug md:mt-2 md:text-xl md:font-bold"
            :class="{ 'md:text-s-600': state === 'inactive' }"
          >
            {{ headline }}
          </h2>
        </BaseLink>
      </div>

      <!-- Mobile -->
      <HorizontalGeneralCard
        class="block md:hidden"
        :published-date="publishedDate"
        :slug="slug"
        :duration="duration"
        :title="headline"
        :cover-url="coverUrl"
      />
    </section>
  </article>
</template>

<style>
@keyframes progress {
  from {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.progress-animation {
  animation: progress 9s 1 linear;
}

.pause-animation {
  animation-play-state: paused;
}
</style>
