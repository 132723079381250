<script setup lang="ts">
import { PropType } from 'vue'
import { ChartDataset } from 'chart.js'

defineProps({
  datasets: {
    type: Object as PropType<ChartDataset[]>,
    required: true,
  },
})

const emit = defineEmits(['click-legend-item'])

const onToggle = (datasetIndex, isVisible) =>
  emit('click-legend-item', datasetIndex, isVisible)

/*
  Legend color can also be a patterns or gradients,
  which are objects. We currently don't support other
  but string color.

  See:
  - https://www.chartjs.org/docs/latest/general/colors.html
*/
const getBackgroundColor = (color) => {
  return typeof color === 'string' ? color : ''
}
</script>

<template>
  <section class="flex flex-wrap justify-center gap-3 md:gap-x-6">
    <MineralChartLegendItem
      v-for="(dataset, id) in datasets"
      :key="id"
      class="md:hidden"
      :text="dataset.label"
      :color="getBackgroundColor(dataset.backgroundColor)"
      @click="(isVisible) => onToggle(id, isVisible)"
    />
    <MineralChartLegendItemSwitch
      v-for="(dataset, id) in datasets"
      :key="id"
      class="hidden md:flex"
      :text="dataset.label"
      :color="getBackgroundColor(dataset.backgroundColor)"
      @click="(isVisible) => onToggle(id, isVisible)"
    />
  </section>
</template>
