<script setup lang="ts">
import IconCheck from '@/assets/icons/icon-check.svg'
import IconClose from '@/assets/icons/close.svg'

const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['click'])
const isVisible = ref(true)

const onToggle = () => {
  isVisible.value = !isVisible.value
  emit('click', isVisible.value)
}

const color = computed(() => {
  return isVisible.value ? props.color : '#cacbd3'
})
</script>

<template>
  <div
    class="flex w-fit cursor-pointer select-none flex-row gap-x-2"
    @click.prevent.stop="onToggle"
  >
    <div>
      {{ text }}
    </div>
    <div>
      <div
        class="bg-grey-400 relative block h-6 w-11 rounded-full"
        :style="{ backgroundColor: color }"
      >
        <div
          class="absolute top-[2px] h-5 w-5 rounded-full bg-black transition-all"
          :class="isVisible ? 'right-[2px]' : 'left-[2px]'"
        ></div>
        <IconCheck
          v-if="isVisible"
          class="absolute left-1 top-1/2 h-4 w-4 -translate-y-1/2"
        />
        <IconClose
          v-else
          class="absolute right-1 top-1/2 h-4 w-4 -translate-y-1/2"
        />
      </div>
    </div>
  </div>
</template>
