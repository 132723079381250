<script setup lang="ts">
import { PropType } from 'vue'
import snakeCase from 'lodash/snakeCase'

const props = defineProps({
  data: {
    type: Array as PropType<any[]>,
    required: true,
  },
  slug: {
    type: String,
    required: true,
  },
})

const updateFrequency = props.data?.at(0)?.updateFrequency?.toUpperCase()
</script>

<template>
  <div class="w-full overflow-auto">
    <div class="min-w-[920px] text-xs md:text-sm lg:text-base">
      <div
        class="grid grid-cols-8 bg-s-300 px-[18px] py-3 font-semibold md:grid-cols-10 md:px-6"
      >
        <div class="col-span-2 md:col-span-4">Products</div>
        <div class="col-span-6 grid grid-cols-6 gap-3">
          <div>{{ updateFrequency }}</div>
          <div>YOY</div>
          <div>YTD</div>
          <div>Today</div>
          <div>Last</div>
          <div class="flex justify-center">Share</div>
        </div>
      </div>
      <div
        class="flex flex-col gap-8 p-5 px-[18px] pb-3 md:p-6 md:pb-5 md:pt-[26px] lg:text-base"
      >
        <MineralIndicatorTableValues
          v-for="product in data"
          :key="snakeCase(product.product)"
          :index-name="product.product"
          :latest-change="product.data.latestChange ?? null"
          :year-on-year="product.data.yearOnYear ?? null"
          :year-to-date="product.data.yearToDate ?? null"
          :today="product.data.today ?? null"
          :last="product.data.last ?? null"
          :frequency="product.updateFrequency"
          :slug="slug"
        />
      </div>
    </div>
  </div>
</template>
