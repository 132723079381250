<script setup lang="ts">
import IconCheck from '@/assets/icons/arrow-drop-up.svg'

const props = defineProps({
  index: {
    type: Object,
    default: null,
  },
})

const indexValue = computed(() => (props.index ? props.index.value : null))
const isPositive = computed(() => indexValue.value > 0)
const isZero = computed(() => indexValue.value === 0)
const isEmpty = indexValue.value === null

const textColor = computed(() => {
  if (isEmpty) return 'text-gray-200'
  if (isZero.value) return 'text-black'
  return indexValue.value >= 0 ? 'text-green' : 'text-error-light'
})
const formattedValue = computed(() =>
  indexValue.value === 0 ? '0.0' : indexValue.value,
)
const value = isEmpty ? '---' : `${formattedValue.value}${props.index?.unit}`
</script>

<template>
  <div :class="textColor" class="flex items-center gap-1">
    <span>{{ value }}</span>
    <div>
      <IconCheck
        v-if="!isEmpty && !isZero"
        class="flex-shrink-0"
        :class="[textColor, isPositive ? 'rotate-180' : '']"
      />
    </div>
  </div>
</template>
