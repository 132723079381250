<script setup lang="ts">
const props = defineProps({
  indexName: {
    type: String,
    required: true,
  },
  latestChange: {
    type: Object,
    default: null,
  },
  yearOnYear: {
    type: Object,
    default: null,
  },
  yearToDate: {
    type: Object,
    default: null,
  },
  today: {
    type: Number,
    default: null,
  },
  last: {
    type: Number,
    default: null,
  },
  frequency: {
    type: String,
    required: true,
  },
  slug: {
    type: String,
    required: true,
  },
})

const title = props.indexName + ' Index'

const productPageURL = computed(() => {
  return `https://www.benchmarkminerals.com/price-assessments/${props.slug}/?tab=chart`
})

const linkedinSharableLink = computed(() => {
  return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    productPageURL.value,
  )}&title=${encodeURI(title)}`
})
const latestChangeValue = computed(() => {
  return props.latestChange
    ? `${props.latestChange.value}${props.latestChange.unit}`
    : null
})

const latestChangeTweet = latestChangeValue.value
  ? `${props.frequency}: ${latestChangeValue.value}`
  : ''

const yearOnYearValue = computed(() => {
  return props.yearOnYear
    ? `${props.yearOnYear.value}${props.yearOnYear.unit}`
    : null
})

const yearOnYearTweet = yearOnYearValue.value
  ? `YOY: ${yearOnYearValue.value}`
  : ''

const todayTweet = props.today ? `TODAY: ${props.today}` : ''
const postBase = computed(() => {
  return `${title}
====================
${todayTweet}
${latestChangeTweet}
${yearOnYearTweet}
`
})
const post = computed(() => {
  const text = `${postBase.value}

Source: @Benchmarkmin
See more at: ${productPageURL.value}`
  return encodeURIComponent(text)
})
const twitterLink = computed(() => {
  return `https://twitter.com/intent/tweet?text=${post.value}`
})
</script>

<template>
  <div class="grid grid-cols-8 md:grid-cols-10">
    <!-- Index label -->
    <div class="col-span-2 font-semibold md:col-span-4">
      {{ indexName }}
    </div>
    <!-- Index data -->
    <div
      class="col-span-6 grid grid-cols-6 place-content-center gap-3 font-semibold lg:text-sm"
    >
      <MineralIndicatorTableValue :index="latestChange" />
      <MineralIndicatorTableValue :index="yearOnYear" />
      <MineralIndicatorTableValue :index="yearToDate" />

      <div class="flex items-center">{{ today ?? '---' }}</div>
      <div class="flex items-center">{{ last ?? '---' }}</div>
      <TooltipShare
        :twitter-link="twitterLink"
        :linkedin-link="linkedinSharableLink"
        :link="productPageURL"
        class="flex justify-center"
      />
    </div>
  </div>
</template>
