<script setup lang="ts">
import { PropType } from 'vue'
import { AnyContent } from '@/models/content'
import HorizontalGeneralCard from '@/components/cards/HorizontalGeneralCard.vue'
import HorizontalDownloadCard from '@/components/cards/HorizontalDownloadCard.vue'

defineProps({
  contents: {
    type: Array as PropType<AnyContent[]>,
    required: true,
  },
})

const cards = {
  article: HorizontalGeneralCard,
  video: HorizontalGeneralCard,
  presentation: HorizontalDownloadCard,
  magazine: HorizontalDownloadCard,
}
</script>

<template>
  <BaseSection title="More from Benchmark">
    <div class="grid-cols-2 flex-wrap gap-x-5 md:grid">
      <component
        :is="cards[content.contentType]"
        v-for="content in contents"
        :key="content.id"
        v-bind="content"
        :pills="content.tags"
        class="show-first-three md:show-all md:hide-2nd-card-border border-b-2 border-s-200"
      />
    </div>

    <div class="mt-2 flex justify-center md:mt-7">
      <BaseLink variant="tertiary" href="/search">
        <span class="md:hidden">See more</span>
        <span class="hidden md:block">Click to view more from Benchmark</span>
      </BaseLink>
    </div>
  </BaseSection>
</template>
