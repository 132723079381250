<script setup lang="ts">
import { PropType } from 'vue'
import { Line } from 'vue-chartjs'
import { TChartOptions } from 'vue-chartjs/dist/types'
import CrosshairPlugin from 'chartjs-plugin-crosshair'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  LineElement,
  PointElement,
  TimeScale,
} from 'chart.js'
import numeral from 'numeral'
import 'chartjs-adapter-date-fns'
import { enUS } from 'date-fns/locale'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  LineElement,
  PointElement,
  TimeScale,
)

const props = defineProps({
  chartData: {
    type: Object as PropType<any>,
    required: true,
  },
  turnOffAnimation: {
    type: Boolean,
    default: false,
  },
  yAxisTitle: {
    type: String,
    default: '',
  },
  showLinePoints: {
    type: Boolean,
    default: true,
  },
})

const { $theme } = useNuxtApp()

const chartOptions: TChartOptions<'line'> | any = computed(() => {
  return {
    animation: !props.turnOffAnimation,
    hover: {
      intersect: false,
    },
    elements: {
      point: {
        radius: props.showLinePoints ? 3 : 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        titleFont: {
          family: 'Montserrat',
        },
        bodyFont: {
          family: 'Montserrat',
        },
        callbacks: {
          label(data: any) {
            const label = data.dataset.label
            return `${label}: ${numeral(data.raw).format('0,0.1')}`
          },
        },
      },
      crosshair: {
        line: {
          color: $theme.colors.s['600'],
          width: 1,
        },
        zoom: {
          enabled: false,
        },
        snap: {
          enabled: true,
        },
        sync: {
          enabled: false,
        },
      },
    },
    scales: {
      y: {
        ticks: {
          maxTicksLimit: 7,
          font: { family: 'Montserrat', size: '10px' },
        },
        title: {
          display: Boolean(props.yAxisTitle),
          text: props.yAxisTitle,
          font: { family: 'Montserrat', size: '12px' },
        },
      },
      x: {
        type: 'time',
        time: {
          unit: 'month',
          displayFormats: {
            datetime: 'MMM d, yyyy',
          },
        },
        bounds: 'ticks',
        adapters: {
          date: {
            locale: enUS,
          },
        },
        ticks: {
          autoSkip: true,
          autoSkipPadding: 10,
          font: { family: 'Montserrat', size: '10px' },
        },
      },
    },
  }
})

const chartRef = ref()
const onClickLegendItem = useOnClickLegendItem(chartRef)
</script>

<template>
  <MineralChartLegends
    class="mb-1"
    :datasets="chartData.datasets"
    @click-legend-item="onClickLegendItem"
  />
  <Line
    v-if="chartData"
    ref="chartRef"
    :chart-data="chartData"
    :styles="{ minHeight: '0', height: '100%', minWidth: '0', width: '100%' }"
    :chart-options="chartOptions"
    :plugins="[CrosshairPlugin]"
  />
</template>
