<script setup lang="ts">
import { PropType } from 'vue'
import { Category, ContentType, Tag } from '@/models/content'

const props = defineProps({
  contentType: {
    type: String as PropType<ContentType>,
    default: 'article',
    validator(value: string) {
      return ['article', 'chart', 'video'].includes(value)
    },
  },
  slug: {
    type: [String, Number],
    required: true,
  },
  publishedDate: {
    type: String,
    required: true,
  },
  duration: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  excerpt: {
    type: String,
    required: true,
  },
  pills: {
    type: Array as PropType<Tag[] | Category[]>,
    required: true,
  },
  pillsLimit: {
    type: Number,
    default: 2,
  },
  coverUrl: {
    type: String,
    required: true,
  },
})

const formattedDuration = useFormattedDuration(
  props.contentType,
  props.duration,
)

const limitedPills = computed(() => props.pills.slice(0, props.pillsLimit))

const href = useContentHref(props.contentType, props.slug)
const coverPosition = useCoverPosition(props.coverUrl)
</script>

<template>
  <BaseLink :href="href" as="a" variant="unstyled" class="card-hover-md">
    <article class="bg-s-900">
      <div class="relative w-full shrink-0 pb-1/2 lg:h-96 lg:pb-0">
        <img
          height="500"
          width="500"
          :src="$cloudinary.resize(coverUrl, 'featured-latest-news-thumbnail')"
          class="absolute h-full min-w-full object-cover"
          :class="coverPosition"
          alt=""
        />

        <div class="absolute top-0 h-full w-full border-2 border-black/10" />

        <div class="absolute right-3 top-3">
          <ContentTypeBadge :type="contentType" />
        </div>
      </div>

      <div class="p-4 md:p-8">
        <p class="text-xs font-semibold text-s-600">
          {{ publishedDate }} · {{ formattedDuration }}
        </p>
        <h3
          class="mt-2 text-lg font-bold leading-snug text-white md:text-4xl md:font-bold"
        >
          {{ title }}
        </h3>
        <div class="mt-2 text-base text-s-150 line-clamp-6" v-html="excerpt" />
        <div class="mt-4 flex flex-wrap gap-2">
          <LinkedPill
            v-for="pill in limitedPills"
            :key="pill.id"
            :type="pill.__typename"
            :slug="pill.slug"
            variant="yellow"
          >
            {{ pill.name }}
          </LinkedPill>
        </div>
      </div>
    </article>
  </BaseLink>
</template>
