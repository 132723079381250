<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
  },
})

const emit = defineEmits(['click'])
const isVisible = ref(true)

const onToggle = () => {
  isVisible.value = !isVisible.value
  emit('click', isVisible.value)
}

const colors = computed(() => {
  const color = isVisible.value ? props.color : '#cacbd3'
  const twentyPercentInHexadecimal = '33'
  const backgroundColor = `${color}${twentyPercentInHexadecimal}`
  const borderColor = `${color}`
  return { backgroundColor, borderColor }
})
</script>

<template>
  <button
    class="rounded-full border py-2 px-4 text-xs font-semibold transition-colors"
    data-testid="chart-legend-item"
    :style="colors"
    @click.prevent.stop="onToggle"
  >
    {{ text }}
  </button>
</template>
