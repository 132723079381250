<script setup lang="ts">
import { PropType } from 'vue'
import { AnyContent, Article } from '@/models/content'

const props = defineProps({
  featuredArticles: {
    type: Array as PropType<Article[]>,
    required: true,
  },
  articles: {
    type: Array as PropType<Article[]>,
    required: true,
  },
  maxArticles: {
    type: Number,
    required: true,
  },
})

const featuredArticle = ref<Article>()
const otherArticles = ref<Article[]>()

if (props.featuredArticles.length) {
  featuredArticle.value = [...props.featuredArticles].shift()

  otherArticles.value = props.articles.filter(
    (obj) => obj.id !== featuredArticle.value.id,
  )

  if (otherArticles.value.length === props.maxArticles)
    otherArticles.value.pop()
} else {
  otherArticles.value = [...props.articles]
  featuredArticle.value = otherArticles.value.shift()
}

const arrayPills = (data: AnyContent) => {
  return useFormattedPillsData(data)
}
</script>

<template>
  <BaseSection title="Data Visualisation">
    <div class="flex-row-reverse gap-x-14 lg:flex">
      <section v-if="featuredArticle" class="mb-4 lg:mb-0 lg:w-3/4">
        <FeaturedChartCard
          :slug="featuredArticle.slug"
          :published-date="featuredArticle.publishedDate"
          :duration="featuredArticle.duration"
          :title="featuredArticle.title"
          :excerpt="featuredArticle.excerpt"
          :pills="arrayPills(featuredArticle)"
          :cover-images="{
            coverUrl: featuredArticle.coverUrl,
            thumbnails: featuredArticle.thumbnails,
          }"
        />
      </section>

      <section v-if="otherArticles.length" class="lg:w-1/4">
        <TextCard
          v-for="article in otherArticles"
          :key="article.id"
          class="border-b-2 border-s-200 first:border-t-2 last:border-0 lg:first:border-t-0 lg:last:border-b-2"
          :slug="article.slug"
          :published-date="article.publishedDate"
          :duration="article.duration"
          :title="article.title"
          :excerpt="article.excerpt"
          data-testid="data-visualization-link"
        />
      </section>
    </div>

    <div class="mt-2 flex justify-center md:mt-6 lg:mt-10">
      <BaseLink variant="tertiary" href="/data-visualisation">
        <span class="md:hidden">See more</span>
        <span class="hidden md:block">
          Click to view more Data Visualisation
        </span>
      </BaseLink>
    </div>
  </BaseSection>
</template>
